
/****************/
/* SIGN IN PAGE */
/****************/
body {
  background-color: #fafafa;
}

.navbar-brand {
/*  margin: 1.5em auto;*/
}

.center-form {
  /*max-width: 33%;*/
}

.center-form.col {
  padding: 3em 3em 2em 3em;
  border: solid 1px #eee;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
  border-radius: 0.3em;
  background-color: #fff;
}

.form-group label {
    padding-right: em !important;
}
.center-form.col .float-right.btn-toolbar button {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
  background-color: #cda24d;
  border-color: #cda24d;
}

/*************/
/* LIST PAGE */
/*************/
body {
  background-color: #fafafa;
}

.navbar {
  background-color: #e0e0e0;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  /*position: fixed;*/
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}

.left-form {
    margin-left: 2.5em !important;
}

.btn-toolbar {
  margin: 1em 2em 1em;
}

.left-form .btn-toolbar {
  margin: 0 0 1em 0;
}

.modal-body .btn-toolbar {
  margin: .5em 0;
}

.btn-default {
  background-color: #cda24d !important;
  border-color: #cda24d !important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.btn {
  text-transform: uppercase;
  font-size: .9em;
  font-weight: 500;
}

.text-info, .text-success {
  color: #000 !important;
}

.text-success.fa-wallet, .text-success.fa-check-circle {
  color: #28a745 !important;
}

.fa-file-upload {
    color: #cda24d !important;
}

.fa-file-download, .btn-link .fa-eye, .fa-user, .user-label, .fa-sign-out-alt, .fa-question-circle{
    color: #724603 !important;
}

.table-striped tr {
  background-color: transparent !important;
}

.table-responsive {
  margin: 0 2em;
  width: auto;
}

.table thead th {
    border-style: none;
}

.table tbody {
  border: solid 1px #eee;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.modal-header {
  background-color: #cda24d !important;
  border-color: #cda24d !important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12); 
}

.modal-body {
    background-color: #fafafa;
    border-bottom-left-radius: .3em;
    border-bottom-right-radius: .3em;
}
.modal-body .table tbody {
    box-shadow: none;
    border-style: none;
    background-color: transparent
}

.modal-body .table tbody td {
    border-style: none
}

.table td, .table th {
  text-align: left !important;
  padding: .35em 2em;
}

.table th {
  vertical-align: middle !important;
  padding: 1.3em 2em;
}

table#detailsTable td:first-child {
  font-weight: bold;
}

table#detailsTable td {
  padding: 0.8em;
}

.pagination .disabled button.page-link {
  color: #999;
}

.pagination .active button.page-link {
  color: #cda24d;
  background-color: transparent;
  border-style:none;
}

.pagination button.page-link {
  margin: 0 !important;
}

/*********************/
/* VERIFICATION PAGE */
/*********************/
body {
  background-color: #fafafa;
}

.navbar-brand {

}


table#detailsTable
{
  margin: 2em;
  width: auto;
  background-color: transparent
}

table#detailsTable tbody {
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
}

.btn-toolbar div {
    left: 51%;
    position: relative;
    margin-top: 2em;
}

/*
.btn-toolbar div:nth-child(2) {
  left:39%
}
*/

.fa-user-check {
    color: #cda24d !important;
}

.fa-eye, .btn-secondary {
    color: #724603 !important;
}

.modal-body .btn-toolbar {
  margin: .5em 0;
}

.modal-body {
    background-color: #fdf7ed;
}
.modal-body .table tbody {
    box-shadow: none;
    border-style: none;
    background-color: transparent
}

.modal-body .table tbody td {
    border-style: none
}

.pagination .disabled button.page-link {
  color: #999;
}

.pagination .active button.page-link {
  color: #cda24d;
  background-color: transparent;
  border-style:none;
}

.pagination button.page-link {
  margin: 0 !important;
}

.pdf-pagination {
  margin-top: 1em;
}

.modal-header .close {
  margin: 0;
  padding: 0;
}

.react-pdf__Page__canvas {
  display: unset !important;
}
