.rootDiv {
  margin: 10px;
}

/* Added to prevent dropdown menu from geting cut off */
.table-responsive {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.pickuprightlist-cell {
  colspan: ;
}

.open-button {
}

body {
  background-color: #feffff;
}

.btn-default:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled):active, .show>.btn-default.dropdown-toggle {
  color: black;
  background-color: #d4d4d4;
  border-color: ##8c8c8c;
}

.btn-default:hover, .btn-default:focus, .open>.button-default.dropdown-toggle {
  color: #cda24d;
  background-color: #d4d4d4;
  border-color: ##8c8c8c;
}

.banner {
  background: url(/img/blockchain-world.jpg) 50% 50% no-repeat;
  background-size: 100%;
  display: block;
  height: 100px;
  max-width: 100%;
}

.container {
  margin-left: 0px;
  width: 100%;
}

.form-control-auto-width {
  width: auto;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #775b22;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #775b22;
}

.pagination > li > a:hover,
.pagination > li > a:focus {
  color: #775b22;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover {
  color: #775b22;
  background-color: #e7e7e7;
  border-color: #e7e7e7;
}

/*
* Isn't there some way to reuse from awesomefont css in pure css?
*/
.tmining-navright-cog > .dropdown-toggle:before {
  width: 1.28571429em;
  text-align: center;

  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: "\f0f3";
}

.tmining-user > a:before {
  width: 1.28571429em;
  text-align: center;

  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: "\f2c0";
}

.tmining-navright-cog-animated > .dropdown-toggle:before {
  width: 1.28571429em;
  text-align: center;

  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: "\f0f3";
  /*
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  animation-name: fa-spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: initial;
  animation-iteration-count: infinite;
  animation-direction: initial;
  animation-fill-mode: initial;
  animation-play-state: initial;
*/

  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  animation-name: ring;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-delay: initial;
  animation-iteration-count: infinite;
  animation-direction: initial;
  animation-fill-mode: initial;
  animation-play-state: initial;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
}

.btn-default {
  margin: 5px;
}

.btn-search {
  margin-bottom: 20px;
}

.btn.btn-secondary {
  color: black;
  background-color: rgba(205, 162, 77, 0.1);
  border-color: #ccc;
}

.btn.btn-link,
.btn.btn-link.focus,
.btn.btn-link:focus,
.btn.btn-link.active,
.btn.btn-link:active,
.btn.btn-link:active:focus,
.btn.btn-link.hover,
.btn.btn-link:hover {
  color: #cda24d;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  box-shadow: none !important;
}


.page-item.active > .page-link {
  /*color: #cda24d;*/
  background-color: #cda24d;
  border-color: transparent;
  outline: none !important;
  box-shadow: none !important;  
  color: black;
  background-color: rgba(205, 162, 77, 0.1);
  border-color: #ccc;
}

.page-link:not(:disabled):not(.disabled) {
  background-color: #cda24d;
  border-color: transparent;
  outline: none !important;
  box-shadow: none !important;
  margin: 2px;
  color: white;    
  color: black;
  background-color: rgba(205, 162, 77, 0.1);
  border-color: #ccc;
}

.page-item.disabled > .page-link {
  color: #cda24d;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  box-shadow: none !important;  
}

button.page-link,
button.page-link.focus,
button.page-link:focus,
button.page-link.active,
button.page-link:active,
button.page-link:active:focus,
button.page-link.hover,
button.page-link:hover {
  color: #cda24d;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  box-shadow: none !important;
}



.Select--multi .Select-value,
.Select-value {
  color: #cda24d;
}

.Select-placeholder {
  color: #cda24d;
}

.Select {
  outline-color: #cda24d;
}

table.dataTable thead .sorting:after {
  content: none;
}

table.dataTable thead .sorting_asc:after {
  content: none;
}

table.dataTable thead .sorting_desc:after {
  content: none;
}

.table > tbody > tr > td {
  vertical-align: middle;
  display: table-cell; /* Courtesy: @Tracker1, for consistency across browsers */
  text-align: center;
}

#detailsTable > tbody > tr > td {
  vertical-align: middle;
  display: table-cell; /* Courtesy: @Tracker1, for consistency across browsers */
  text-align: left;
}

.table > thead > tr > th {
  text-align: center;
}

ul.pagination {
  display: inline-flex;
}

.notification {
  margin-top: none;
}

.selectedProfile {
  background-color: aquamarine;
}

.greenLight {
  color: forestgreen;
}

.redLight {
  color: darkred;
}

.tmining-document-comment {
}

.tmining-document-comment-header {
}

.tmining-document-comment-user {
}

.tmining-document-comment-org {
}

.tmining-document-comment-timestamp {
}

.tmining-document-comment-textarea {
  width: 100%;
}

.inline-div {
  display: inline-table;
}

.inline-subdiv {
  display: table-row;
}

.inline-contentdiv {
  display: table-cell;
  vertical-align: middle;
}

.form-check-input {
  position: unset;
}

.button.cancel.bl {
}

.mw-40ch {
  max-width: 40ch;
}

.left-form {
  margin-right: auto;
  max-width: 576px;
}

.right-form {
  margin-left: auto;
  max-width: 576px;
}

.center-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 576px;
}

.modal-title {
  margin-left: auto;
  margin-right: auto;  
}

.modal-width {
  max-width: 650px;
}

.modal-width2 {
  max-width: 800px;
}

textarea.form-control.displayonly[readonly] {
  background-color: transparent;
  border: none;
  resize: none;
}

div.border-primary {
  border-color: rgba(205, 162, 77, 0.1) !important;
}

.user-label {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.padding-sides-0 {
  padding-left: 0;
  padding-right: 0;
}

.react-pdf-no-display {
  display: none;
}